import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import ProductCard from './ProductCard';
import NavBar from './NavBar';
import '../styles/ProductsDisplay.css';
import logoImage from '../images/lastoria.png';

const ProductsDisplay = () => {
    const [groupedProducts, setGroupedProducts] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { categoryName } = useParams();
    const [navExpanded, setNavExpanded] = useState(false);

    const toggleNav = () => {
        setNavExpanded(!navExpanded);
    };

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);

            let productsQuery = collection(firestore, 'menuItems');
            if (categoryName) {
                productsQuery = query(productsQuery, where('category', '==', categoryName));
            }

            const querySnapshot = await getDocs(productsQuery);
            const productsList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            const grouped = productsList.reduce((acc, product) => {
                const { subcategory } = product;
                acc[subcategory] = acc[subcategory] || [];
                acc[subcategory].push(product);
                return acc;
            }, {});

            setGroupedProducts(grouped);
            setIsLoading(false);
        };

        fetchProducts();
    }, [categoryName]);
    
    return (
        <div className='product-display-container'>
            {/* <NavBar toggleNav={toggleNav} navExpanded={navExpanded} /> */}
            {isLoading ? (
                <div className="loading-container">
                    <img src={logoImage} alt="Loading..." className="logo-spinner" />
                </div>
            ) : (
                <>
                    <h1>{categoryName}</h1>
                    {Object.entries(groupedProducts).map(([subcategoryName, products]) => (
                        <section key={subcategoryName}>
                            <h2>{subcategoryName}</h2>
                            <div className="product-grid">
                                {products.map(product => (
                                    <ProductCard key={product.id} product={product} />
                                ))}
                            </div>
                        </section>
                    ))}
                </>
            )}
        </div>
    );
};

export default ProductsDisplay;
