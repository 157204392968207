import React, { useState, useEffect } from 'react';
import Rating from 'react-rating-stars-component';
import { collection, addDoc, getDocs, serverTimestamp, doc, getDoc, query, where } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useAuth } from './auth/AuthContext'; // Assuming you have an AuthContext to get the current user
import { ToastContainer, toast } from 'react-toastify'; // Importing toast notifications
import 'react-toastify/dist/ReactToastify.css';
import '../styles/RatingComponent.css';

const RatingComponent = ({ language }) => {
  const [currentRating, setCurrentRating] = useState(0);
  const [clientName, setClientName] = useState(''); // State for client's name
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const { currentUser } = useAuth(); // Get the current logged-in user from AuthContext

  // Fetch existing ratings and comments
  useEffect(() => {
    const fetchRatingsAndComments = async () => {
      const ratingsCollection = collection(firestore, 'ratings');
      const querySnapshot = await getDocs(ratingsCollection);
      const commentsArray = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.comment && data.name) {
          commentsArray.push(`${data.name}: ${data.comment}`); // Combine name and comment
        }
      });

      setComments(commentsArray); // Set the fetched comments
    };

    fetchRatingsAndComments();
  }, []);

  // Helper function to send notifications to all users with the specified role
  const sendNotificationToRole = async (role, message, ratingId) => {
    try {
      const usersQuery = query(collection(firestore, 'users'), where('role', '==', role));
      const usersSnapshot = await getDocs(usersQuery);

      usersSnapshot.forEach(async (userDoc) => {
        await addDoc(collection(firestore, `users/${userDoc.id}/notifications`), {
          message: message,
          ratingId: ratingId,
          createdAt: serverTimestamp(),
          isRead: false, // Unread by default
        });
      });
    } catch (error) {
      console.error(`Error sending notification to ${role}:`, error);
    }
  };

  // Handle form submission and save rating + comment
  const handleSubmit = async () => {
    if (currentRating > 0) {
      try {
        // Add new rating to Firestore
        const ratingDoc = await addDoc(collection(firestore, 'ratings'), {
          name: clientName || 'Anonymous', // Save the client's name or default to 'Anonymous'
          comment: comment,
          rating: currentRating,
          createdAt: serverTimestamp(),
        });

        // Add notifications for admins and editors
        await sendNotificationToRole('admin', `${clientName || 'A user'} added a new rating.`, ratingDoc.id);
        await sendNotificationToRole('editor', `${clientName || 'A user'} added a new rating.`, ratingDoc.id);

        // Clear form fields after submission
        setClientName('');
        setComment('');
        setCurrentRating(0);

        // Fetch comments again to display the new comment without page reload
        const newCommentsSnapshot = await getDocs(collection(firestore, 'ratings'));
        const updatedCommentsArray = [];
        newCommentsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.comment && data.name) {
            updatedCommentsArray.push(`${data.name}: ${data.comment}`);
          }
        });
        setComments(updatedCommentsArray);

        toast.success(language === 'En' ? 'Rating submitted successfully!' : 'تم إرسال التقييم بنجاح!');
      } catch (error) {
        console.error('Error submitting rating:', error);
        toast.error(language === 'En' ? 'Error submitting rating. Please try again.' : 'خطأ في إرسال التقييم. حاول مرة أخرى.');
      }
    } else {
      toast.error(language === 'En' ? 'Please select a rating' : 'الرجاء اختيار تقييم');
    }
  };

  return (
    <div className='rate-container'>
      <ToastContainer /> {/* Toast notification container */}
      <h3 className='rate-us'>{language === 'En' ? 'Rate Us' : 'قيمنا'}</h3>
      <div className="rating">
        <Rating
          count={5}
          value={currentRating}
          size={24}
          activeColor="#ffd700"
          inactiveColor="#ddd"
          onChange={(rate) => setCurrentRating(rate)}
          classNames="rating"
        />
      </div>
      <input
        type="text"
        placeholder={language === 'En' ? 'Your Name (optional)' : '(اختياري) اسمك'}
        value={clientName}
        onChange={(e) => setClientName(e.target.value)}
        className="client-name-input"
      />
      <textarea
        placeholder={language === 'En' ? 'Leave a comment (optional)' : 'اترك تعليقا (اختياري)'}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="comment-box"
      ></textarea>
      <div className="submit-btn-container">
        <button onClick={handleSubmit} className="submit-btn">
          {language === 'En' ? 'Submit' : 'إرسال'}
        </button>
      </div>

      {/* <div className="comments-section">
        <h4>{language === 'En' ? 'Comments' : 'التعليقات'}</h4>
        {comments.length > 0 ? (
          comments.map((comment, index) => <p key={index}>{comment}</p>)
        ) : (
          <p>{language === 'En' ? 'No comments yet.' : 'لا توجد تعليقات بعد.'}</p>
        )}
      </div> */}
    </div>
  );
};

export default RatingComponent;
