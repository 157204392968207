import React, { createContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductsDisplay from './components/ProductsDisplay';
import ProductDetail from './components/ProductDetail';
import ProductUploadForm from './components/ProductUploadForm';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Redirect from './components/Redirect';
import ProductEditForm from './components/ProductEditForm';
import AdminDashboard from './components/AdminDashboard';
import EditorDashboard from './components/EditorDashboard';
import NotificationsPage from './components/NotificationsPage';
import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp';
import ProtectedRoute from './components/auth/ProtectedRoute';
import { AuthProvider } from './components/auth/AuthContext';
import UserProfile from './components/auth/UserProfile';
import NavBar from './components/NavBar';
import RatingPage from './components/RatingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/AdminDashboard.css';
import './styles/UserProfile.css';

export const NavContext = createContext();

const App = () => {
  const [navExpanded, setNavExpanded] = useState(false);

  const toggleNav = () => {
    console.log("toggleNav called");
    setNavExpanded((prev) => !prev);
  };

  const closeNav = () => {
    setNavExpanded(false);
  };

  return (
    <AuthProvider>
      <Router>
        <NavContext.Provider value={{ navExpanded, toggleNav, closeNav }}>
          <NavBar />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/category/:categoryName" element={<ProductsDisplay />} />
            <Route path="/admin" element={<ProtectedRoute element={AdminDashboard} />} />
            <Route path="/profile" element={<ProtectedRoute element={UserProfile} />} />
            <Route path="/editor" element={<ProtectedRoute element={EditorDashboard} requiredRole="editor" />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route
            path="/notifications"
            element={
              <ProtectedRoute element={NotificationsPage} allowedRoles={['admin', 'editor']} />
            }
          />
            <Route path="/rating" element={<RatingPage />} />
            <Route path="/Facebook" element={<Redirect to="https://www.facebook.com/lastoria.hebron/" />} />
          </Routes>
          <Footer />
        </NavContext.Provider>
      </Router>
    </AuthProvider>
  );
};

export default App;
