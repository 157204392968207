import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore, requestFcmToken } from '../firebaseConfig'; // Import requestFcmToken
import ProductEditForm from './ProductEditForm';
import RatingsResults from './RatingsResults';
import NotificationsPage from './NotificationsPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AdminDashboard.css';
import { useAuth } from './auth/AuthContext';

const EditorDashboard = () => {
  const [activeSection, setActiveSection] = useState('manage-products');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collection(firestore, `users/${currentUser.uid}/notifications`),
      where('isRead', '==', false)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setUnreadNotificationsCount(snapshot.size);
    });

    return () => unsubscribe();
  }, [currentUser]);

  // Request FCM token for notifications
  useEffect(() => {
    const getFcmToken = async () => {
      const token = await requestFcmToken();
      if (token) {
        console.log('FCM Token for editor:', token);
        // Store the token in Firestore if needed
      }
    };
    getFcmToken();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setIsSidebarOpen(false);
  };

  return (
    <div className="admin-dashboard">
      <ToastContainer />
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Editor Dashboard</h2>
        </div>
        <button className="menu-toggler" onClick={toggleSidebar}>
          ☰
        </button>
        <nav>
          <ul>
            <li className={activeSection === 'manage-products' ? 'active' : ''} onClick={() => handleSectionClick('manage-products')}>
              Manage Products
            </li>
            <li className={activeSection === 'ratings' ? 'active' : ''} onClick={() => handleSectionClick('ratings')}>
              Ratings Results
            </li>
            <li className={activeSection === 'notifications' ? 'active' : ''} onClick={() => handleSectionClick('notifications')}>
              Notifications
              {unreadNotificationsCount > 0 && (
                <span className="notification-count">{unreadNotificationsCount}</span>
              )}
            </li>
          </ul>
        </nav>
      </div>
      <div className="main-content">
        <button className="menu-toggler" onClick={toggleSidebar}>
          ☰
        </button>
        {activeSection === 'manage-products' && (
          <div className="section">
            <h2>Manage Products</h2>
            <ProductEditForm />
          </div>
        )}
        {activeSection === 'ratings' && (
          <div className="section">
            <h2>Ratings Results</h2>
            <RatingsResults />
          </div>
        )}
        {activeSection === 'notifications' && (
          <div className="section">
            <h2>Notifications</h2>
            <NotificationsPage /> {/* NotificationsPage component */}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditorDashboard;
