// // ProtectedRoute.js
// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';

// const ProtectedRoute = ({ element: Component, ...rest }) => {
//   const { currentUser, adminRole } = useAuth();

//   if (!currentUser) {
//     return <Navigate to="/login" />;
//   }

//   if (!adminRole) {
//     return <Navigate to="/" />;
//   }

//   return <Component {...rest} />;
// };

// export default ProtectedRoute;




import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ element: Component, requiredRole, ...rest }) => {
  const { currentUser, userRole } = useAuth();

  // Check if the user is authenticated
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Check if the user has the required role to access the route
  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/" />;
  }

  // If both authentication and role checks pass, render the component
  return <Component {...rest} />;
};

export default ProtectedRoute;
