import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { firestore, requestFcmToken, messaging } from '../firebaseConfig'; // Import requestFcmToken and messaging
import { useAuth } from './auth/AuthContext'; // Assuming you have an auth context to get current user ID
import { onMessage } from 'firebase/messaging'; // Import onMessage from Firebase Messaging
import ProductUploadForm from './ProductUploadForm';
import ProductEditForm from './ProductEditForm';
import RatingsResults from './RatingsResults';
import AddUserForm from './AddUserForm';
import ManageUsers from './ManageUsers';
import NotificationsPage from './NotificationsPage';
import '../styles/AdminDashboard.css';

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState('upload');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const { currentUser } = useAuth(); // Get current user from AuthContext
  const navigate = useNavigate();

  // Fetch unread notifications count from Firestore for the logged-in user
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const userId = currentUser.uid; // Use current user's ID
      const notificationsRef = collection(firestore, `users/${userId}/notifications`);
      const q = query(notificationsRef, where('isRead', '==', false));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.size > 0) {
          setUnreadNotificationsCount(snapshot.size); // Set the count of unread notifications
        } else {
          setUnreadNotificationsCount(0); // Reset to 0 if no unread notifications
        }
      });

      return () => unsubscribe(); // Cleanup listener on component unmount
    }
  }, [currentUser]);

  // Request FCM token when component mounts and store it in Firestore
  useEffect(() => {
    const getFcmToken = async () => {
      try {
        const token = await requestFcmToken();
        if (token && currentUser) {
          console.log('FCM Token for current user:', token);

          // Ensure we use the correct Firestore document path
          const userDocRef = doc(firestore, `users/${currentUser.uid}`);

          // Store the FCM token under the user's document in Firestore
          await setDoc(userDocRef, {
            fcmToken: token
          }, { merge: true }); // Use merge to update the user document without overwriting other fields
        }
      } catch (error) {
        console.error('Error getting FCM token or saving it to Firestore:', error);
      }
    };

    getFcmToken(); // Call the function when component mounts
  }, [currentUser]);

  // Listen for foreground notifications
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Foreground Message received:', payload);

      // Optionally, you can show a toast notification or alert here
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: payload.notification.icon,
      });
    });

    return () => {
      unsubscribe(); // Unsubscribe from the onMessage listener on component unmount
    };
  }, []);

  const goToProfile = () => {
    navigate('/profile');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setIsSidebarOpen(false);
  };

  return (
    <div className="admin-dashboard">
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Admin Dashboard</h2>
          <i
            className="bi bi-person-circle profile-icon"
            onClick={goToProfile}
            title="User Profile"
          ></i>
        </div>
        <button className="menu-toggler" onClick={toggleSidebar}>
          ☰
        </button>
        <nav>
          <ul>
            <li className={activeSection === 'upload' ? 'active' : ''} onClick={() => handleSectionClick('upload')}>
              Upload Product
            </li>
            <li className={activeSection === 'edit' ? 'active' : ''} onClick={() => handleSectionClick('edit')}>
              Edit Products
            </li>
            <li className={activeSection === 'ratings' ? 'active' : ''} onClick={() => handleSectionClick('ratings')}>
              Ratings Results
            </li>
            <li className={activeSection === 'add-user' ? 'active' : ''} onClick={() => handleSectionClick('add-user')}>
              Add User
            </li>
            <li className={activeSection === 'manage-users' ? 'active' : ''} onClick={() => handleSectionClick('manage-users')}>
              Manage Users
            </li>
            <li className={activeSection === 'notifications' ? 'active' : ''} onClick={() => handleSectionClick('notifications')}>
              Notifications
              {unreadNotificationsCount > 0 && (
                <span className="notification-count">{unreadNotificationsCount}</span>
              )}
            </li>
          </ul>
        </nav>
      </div>
      <div className="main-content">
        <button className="menu-toggler" onClick={toggleSidebar}>
          ☰
        </button>
        {activeSection === 'upload' && (
          <div className="section">
            <h2>Upload New Product</h2>
            <ProductUploadForm />
          </div>
        )}
        {activeSection === 'edit' && (
          <div className="section">
            <h2>Edit Existing Products</h2>
            <ProductEditForm />
          </div>
        )}
        {activeSection === 'ratings' && (
          <div className="section">
            <h2>Ratings Results</h2>
            <RatingsResults />
          </div>
        )}
        {activeSection === 'add-user' && (
          <div className="section">
            <h2>Add New User</h2>
            <AddUserForm />
          </div>
        )}
        {activeSection === 'manage-users' && (
          <div className="section">
            <h2>Manage Users</h2>
            <ManageUsers />
          </div>
        )}
        {activeSection === 'notifications' && (
          <div className="section">
            <h2>Notifications</h2>
            <NotificationsPage />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
