// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { doc, getDoc } from 'firebase/firestore';
// import { firestore } from '../firebaseConfig';
// import NavBar from './NavBar';
// import '../styles/ProductDetail.css'; // Ensure the correct path
// import logoImage from '../images/lastoria.png'; // Adjust the path to your logo image

// const ProductDetail = () => {
//   const [product, setProduct] = useState(null);
//   const [isLoading, setIsLoading] = useState(true); // Loading state
//   const { productId } = useParams();
//   const [navExpanded, setNavExpanded] = useState(false);

//   const toggleNav = () => {
//     setNavExpanded(!navExpanded);
//   };

//   useEffect(() => {
//     const fetchProduct = async () => {
//       setIsLoading(true); // Start loading
//       const productRef = doc(firestore, 'menuItems', productId);
//       const productSnapshot = await getDoc(productRef);
//       if (productSnapshot.exists()) {
//         setProduct(productSnapshot.data());
//       }
//       setIsLoading(false); // Stop loading once data is fetched
//     };

//     fetchProduct();
//   }, [productId]);

//   if (isLoading) { // Display loading animation while loading
//     return (
//       <div className="loading-container">
//         <img src={logoImage} alt="Loading..." className="logo-spinner" />
//       </div>
//     );
//   }

//   // Display product details once loading is complete
//   return (
//     <div>
//       {/* <NavBar toggleNav={toggleNav} navExpanded={navExpanded}/> */}
//       <div className="product-detail-container">
//         <img src={product.image} alt={product.name_en} className="product-detail-image" />
//         <div className="product-detail-info">
//           <h1 className="product-detail-title">{product.name_en}</h1>
//           <h1 className="product-detail-title">{product.name_ar}</h1>
//           <h3 className="product-detail-price">Price: ₪{product.price}</h3>
//           <p className="product-detail-description">{product.description_en}</p>
//           <p className="product-detail-description-ar">{product.description_ar}</p>
//           {/* Link to go back can be uncommented or adjusted as needed */}
//           {/* <Link to="/" className="product-detail-back">Go back to menu</Link> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;

// ------------------ In the new code we use the original images not resized image like product display component (to use the resized images by product card component) ------------------------------

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import NavBar from './NavBar';
import '../styles/ProductDetail.css'; // Ensure the correct path
import logoImage from '../images/lastoria.png'; // Adjust the path to your logo image

const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const { productId } = useParams();
  const [navExpanded, setNavExpanded] = useState(false);

  const toggleNav = () => {
    setNavExpanded(!navExpanded);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true); // Start loading
      const productRef = doc(firestore, 'menuItems', productId);
      const productSnapshot = await getDoc(productRef);
      if (productSnapshot.exists()) {
        setProduct(productSnapshot.data());
      }
      setIsLoading(false); // Stop loading once data is fetched
    };

    fetchProduct();
  }, [productId]);

  if (isLoading) { // Display loading animation while loading
    return (
      <div className="loading-container">
        <img src={logoImage} alt="Loading..." className="logo-spinner" />
      </div>
    );
  }

  // Display product details once loading is complete
  return (
    <div>
      {/* <NavBar toggleNav={toggleNav} navExpanded={navExpanded}/> */}
      <div className="product-detail-container">
        <img src={product.image} alt={product.name_en} className="product-detail-image" />
        <div className="product-detail-info">
          <h1 className="product-detail-title">{product.name_en}</h1>
          <h1 className="product-detail-title">{product.name_ar}</h1>
          <h3 className="product-detail-price">Price: ₪{product.price}</h3>
          <p className="product-detail-description">{product.description_en}</p>
          <p className="product-detail-description-ar">{product.description_ar}</p>
          {/* Link to go back can be uncommented or adjusted as needed */}
          {/* <Link to="/" className="product-detail-back">Go back to menu</Link> */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
