// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import './index.css'; // or any global stylesheets you have

// // Assuming your root div has the ID 'root' in your HTML
// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'; // or any global stylesheets you have
import { requestFcmToken } from './firebaseConfig'; // Assuming firebaseConfig is in the same directory

// Assuming your root div has the ID 'root' in your HTML
const root = ReactDOM.createRoot(document.getElementById('root'));

// Register the service worker for Firebase Messaging
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);

      // Request the FCM token after service worker is registered
      requestFcmToken();
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
