import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FaTrashAlt, FaEdit, FaKey } from 'react-icons/fa'; // Import icons
import { firestore } from '../firebaseConfig';
import ModalComponent from './ModalComponent'; // Import the reusable modal component
import '../styles/ManageUsers.css';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [updatedUser, setUpdatedUser] = useState({ firstName: '', lastName: '', role: 'admin' });
  const [newPassword, setNewPassword] = useState(''); // New password state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(firestore, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setMessage({ type: 'error', text: 'Failed to fetch users. Please try again later.' });
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const openEditModal = (user) => {
    setEditingUser(user);
    setUpdatedUser({ firstName: user.firstName, lastName: user.lastName, role: user.role });
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (userId) => {
    setDeletingUserId(userId);
    setIsDeleteModalOpen(true);
  };

  const openPasswordModal = (user) => {
    setEditingUser(user);
    setNewPassword('');
    setIsPasswordModalOpen(true);
  };

  const handleSave = async () => {
    if (editingUser) {
      try {
        await updateDoc(doc(firestore, 'users', editingUser.id), {
          firstName: updatedUser.firstName,
          lastName: updatedUser.lastName,
          role: updatedUser.role,
        });
        setUsers(
          users.map((user) =>
            user.id === editingUser.id ? { ...user, ...updatedUser } : user
          )
        );
        setMessage({ type: 'success', text: 'User updated successfully!' });
        setIsEditModalOpen(false);
      } catch (error) {
        console.error('Error updating user:', error);
        setMessage({ type: 'error', text: 'Failed to update user. Please try again later.' });
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'users', deletingUserId));
      setUsers(users.filter((user) => user.id !== deletingUserId));
      setMessage({ type: 'success', text: 'User deleted successfully!' });
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      setMessage({ type: 'error', text: 'Failed to delete user. Please try again later.' });
    }
  };

  const handlePasswordChange = async () => {
    if (editingUser && newPassword) {
      try {
        setMessage({ type: 'success', text: 'Password updated successfully!' });
        setIsPasswordModalOpen(false);
      } catch (error) {
        console.error('Error updating password:', error);
        setMessage({ type: 'error', text: 'Failed to update password. Please try again later.' });
      }
    }
  };

  return (
    <div className="manage-users">
      <h2>Manage Users</h2>
      {message && <div className={`alert ${message.type}`}>{message.text}</div>}
      {loading ? (
        <p>Loading users...</p>
      ) : users.length === 0 ? (
        <p>No users found.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <FaEdit className="icon edit-icon" onClick={() => openEditModal(user)} title="Edit" />
                  <FaKey className="icon password-icon" onClick={() => openPasswordModal(user)} title="Change Password" />
                  <FaTrashAlt className="icon delete-icon" onClick={() => openDeleteModal(user.id)} title="Delete" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Edit User Modal */}
      <ModalComponent
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit User"
      >
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={updatedUser.firstName}
            onChange={(e) => setUpdatedUser({ ...updatedUser, firstName: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={updatedUser.lastName}
            onChange={(e) => setUpdatedUser({ ...updatedUser, lastName: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role</label>
          <select
            id="role"
            name="role"
            value={updatedUser.role}
            onChange={(e) => setUpdatedUser({ ...updatedUser, role: e.target.value })}
          >
            <option value="admin">Admin</option>
            <option value="manager">Manager</option>
            <option value="editor">Editor</option>
          </select>
        </div>
        <button className="btn btn-save" onClick={handleSave}>
          Save
        </button>
        <button className="btn btn-cancel" onClick={() => setIsEditModalOpen(false)}>
          Cancel
        </button>
      </ModalComponent>

      {/* Delete Confirmation Modal */}
      <ModalComponent
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confirm Deletion"
      >
        <p>Are you sure you want to delete this user?</p>
        <button className="btn btn-delete" onClick={handleDelete}>
          Yes, Delete
        </button>
        <button className="btn btn-cancel" onClick={() => setIsDeleteModalOpen(false)}>
          Cancel
        </button>
      </ModalComponent>

      {/* Change Password Modal */}
      <ModalComponent
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        title="Change Password"
      >
        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <button className="btn btn-save" onClick={handlePasswordChange}>
          Change Password
        </button>
        <button className="btn btn-cancel" onClick={() => setIsPasswordModalOpen(false)}>
          Cancel
        </button>
      </ModalComponent>
    </div>
  );
};

export default ManageUsers;
