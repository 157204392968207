import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebaseConfig';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null); // Handle roles (e.g., admin, editor)
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null); // Error state for handling authentication issues

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          // Fetch the user's role from Firestore
          const userDoc = await getDoc(doc(firestore, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserRole(userData.role); // Save the user's role (e.g., admin, editor, etc.)
          } else {
            setAuthError('User data not found in Firestore.');
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setAuthError('Failed to fetch user data.');
        }
      } else {
        setCurrentUser(null);
        setUserRole(null); // Reset role when user logs out
      }
      setLoading(false); // Stop loading when authentication state is resolved
    });

    return unsubscribe; // Cleanup subscription on component unmount
  }, []);

  const login = async (email, password) => {
    try {
      setLoading(true);
      setAuthError(null); // Reset error state before login attempt
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setAuthError('Invalid credentials. Please try again.');
      console.error('Error during login:', error);
    } finally {
      setLoading(false); // Stop loading after the login attempt
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setUserRole(null); // Reset state on logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, userRole, login, logout, authError }}>
      {!loading && children} {/* Only render children when not loading */}
    </AuthContext.Provider>
  );
};
