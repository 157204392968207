import React from 'react';
import { FaStar } from 'react-icons/fa';
import '../styles/StarRating.css';

const StarRating = ({ rating, onRatingChange, readOnly = false }) => {
  const handleClick = (value) => {
    if (!readOnly) {
      onRatingChange(value);
    }
  };

  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => handleClick(ratingValue)}
              style={{ display: 'none' }}
              disabled={readOnly}
            />
            <FaStar
              size={30}
              color={ratingValue <= rating ? '#ffc107' : '#e4e5e9'}
              className="star"
              onClick={() => handleClick(ratingValue)}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
