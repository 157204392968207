import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Import your custom auth context
import { useNavigate } from 'react-router-dom'; // To redirect the user
import { doc, getDoc } from 'firebase/firestore'; // To get user data from Firestore
import { firestore } from '../../firebaseConfig'; // Firebase Firestore configuration
import '../../styles/Login.css'; // Custom CSS for the login page

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const { login } = useAuth(); // Get login function from auth context
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null); // Clear previous message

    try {
      // User login with Firebase Authentication
      const userCredential = await login(email, password);
      const user = userCredential.user;

      // Fetch user role from Firestore
      const userDoc = await getDoc(doc(firestore, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role) {
          // Navigate to the correct dashboard based on user role
          switch (userData.role) {
            case 'admin':
              navigate('/admin'); // Admin dashboard
              break;
            case 'editor':
              navigate('/editor'); // Editor dashboard
              break;
            case 'user':
              navigate('/dashboard'); // Regular user dashboard
              break;
            default:
              navigate('/'); // Fallback to homepage
              break;
          }
        } else {
          setMessage({ type: 'error', text: 'User role is missing. Please contact support.' });
        }
      } else {
        setMessage({ type: 'error', text: 'User role not found. Please contact support.' });
      }
    } catch (error) {
      console.error('Error logging in: ', error);
      setMessage({ type: 'error', text: 'Error logging in. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-form-container">
      <div className="auth-form-card">
        <h2>Login</h2>
        {message && (
          <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
            {message.text}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setMessage(null); // Clear message on change
              }}
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setMessage(null); // Clear message on change
              }}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'} {/* Show loading message */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
