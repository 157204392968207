import React, { useState } from 'react';
import StarRating from './StarRating';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import '../styles/RatingPage.css';

const RatingPage = () => {
  const [ratings, setRatings] = useState({
    food: 0,
    drink: 0,
    sweet: 0,
    dessert: 0,
    bubbly: 0,
    service: 0,
    cleanliness: 0,
  });

  const [comments, setComments] = useState({
    food: '',
    drink: '',
    sweet: '',
    dessert: '',
    bubbly: '',
    service: '',
    cleanliness: '',
  });

  const [generalComment, setGeneralComment] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState(null);
  const [language, setLanguage] = useState('ar'); // Default language is Arabic

  const handleRatingChange = (category, value) => {
    setRatings((prevRatings) => ({ ...prevRatings, [category]: value }));
  };

  const handleCommentChange = (category, value) => {
    setComments((prevComments) => ({ ...prevComments, [category]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save the feedback to 'ratingPageResponses' in Firestore
      await addDoc(collection(firestore, 'ratingPageResponses'), {
        ...ratings,
        ...comments,
        generalComment,
        email: email || '', // Optional email
        phone: phone || '', // Optional phone
        timestamp: new Date(), // Add timestamp
      });

      setMessage({ type: 'success', text: language === 'ar' ? 'شكراً لتعليقاتكم!' : 'Thank you for your feedback!' });

      // Reset form
      setRatings({
        food: 0,
        drink: 0,
        sweet: 0,
        dessert: 0,
        bubbly: 0,
        service: 0,
        cleanliness: 0,
      });
      setComments({
        food: '',
        drink: '',
        sweet: '',
        dessert: '',
        bubbly: '',
        service: '',
        cleanliness: '',
      });
      setGeneralComment('');
      setEmail('');
      setPhone('');
    } catch (error) {
      setMessage({ type: 'error', text: language === 'ar' ? 'حدث خطأ أثناء إرسال التعليقات. يرجى المحاولة مرة أخرى.' : 'Error submitting feedback. Please try again.' });
    }
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'ar' ? 'en' : 'ar'));
  };

  return (
    <div className="rating-page">
      <div className="rating-content">
        <button onClick={toggleLanguage} className="btn btn-secondary language-toggle">
          {language === 'ar' ? 'English' : 'العربية'}
        </button>
        <div className='rating-header'>
          <h2>{language === 'ar' ? 'نحن نسعى لارضاء تجربتك في لاستوريا!' : 'We strive to satisfy your experience at Lastoria!'}</h2>
          <p>{language === 'ar' ? 'شكراً لزيارتكم لمطعم لاستوريا، نود سماع رأيكم لتجربة أفضل.' : 'Thank you for visiting Lastoria Restaurant, we would like to hear your opinion for a better experience.'}</p>
          <p>{language === 'ar' ? 'لمساعدتنا يرجى تخصيص دقائق لتعبئة هذا الاستبيان.' : 'To help us, please take a few minutes to fill out this survey.'}</p>
          <p>{language === 'ar' ? 'املئ النجوم لتقييم تجربتك، حيث أن نجمة تعني سيء و5 نجوم تعني ممتاز.' : 'Fill in the stars to rate your experience, where one star means poor and five stars means excellent.'}</p>

          {message && (
            <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
              {message.text}
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="rating-section">
            {/* Food Section */}
            <h2>{language === 'ar' ? 'وجبة الطعام:' : 'Food:'}</h2>
            <p>{language === 'ar' ? 'كيف تقيم جودة الطعام الذي تناولته اليوم؟' : 'How would you rate the quality of the food you ate today?'}</p>
            <StarRating rating={ratings.food} onRatingChange={(value) => handleRatingChange('food', value)} />
            <textarea
              className="form-control"
              value={comments.food}
              onChange={(e) => handleCommentChange('food', e.target.value)}
              placeholder={language === 'ar' ? 'تعليقاتك حول الطعام' : 'Your comments on the food'}
            ></textarea>

            {/* Drinks Section */}
            <h2>{language === 'ar' ? 'المشروبات:' : 'Drinks:'}</h2>
            <p>{language === 'ar' ? 'كيف تقيم جودة المشروبات المقدمة؟' : 'How would you rate the quality of the drinks served?'}</p>
            <StarRating rating={ratings.drink} onRatingChange={(value) => handleRatingChange('drink', value)} />
            <textarea
              className="form-control"
              value={comments.drink}
              onChange={(e) => handleCommentChange('drink', e.target.value)}
              placeholder={language === 'ar' ? 'تعليقاتك حول المشروبات' : 'Your comments on the drinks'}
            ></textarea>

            {/* Dessert Section */}
            <h2>{language === 'ar' ? 'الحلويات:' : 'Desserts:'}</h2>
            <p>{language === 'ar' ? 'كيف تقيم جودة الحلويات المقدمة؟' : 'How would you rate the quality of the desserts served?'}</p>
            <StarRating rating={ratings.dessert} onRatingChange={(value) => handleRatingChange('dessert', value)} />
            <textarea
              className="form-control"
              value={comments.dessert}
              onChange={(e) => handleCommentChange('dessert', e.target.value)}
              placeholder={language === 'ar' ? 'تعليقاتك حول الحلويات' : 'Your comments on the desserts'}
            ></textarea>

            {/* Bubbly Section */}
            <h2>{language === 'ar' ? 'الأريجيلة:' : 'Bubbly:'}</h2>
            <p>{language === 'ar' ? 'كيف تقيم جودة الأريجيلة المقدمة؟' : 'How would you rate the quality of the Bubbly served?'}</p>
            <StarRating rating={ratings.sweet} onRatingChange={(value) => handleRatingChange('sweet', value)} />
            <textarea
              className="form-control"
              value={comments.sweet}
              onChange={(e) => handleCommentChange('sweet', e.target.value)}
              placeholder={language === 'ar' ? 'تعليقاتك حول الأريجيلة' : 'Your comments on the hookah'}
            ></textarea>

            {/* Service Section */}
            <h2>{language === 'ar' ? 'الخدمة:' : 'Service:'}</h2>
            <p>{language === 'ar' ? 'هل كان فريق العمل ودوداً ومتعاوناً؟' : 'Was the staff friendly and helpful?'}</p>
            <StarRating rating={ratings.bubbly} onRatingChange={(value) => handleRatingChange('bubbly', value)} />
            <textarea
              className="form-control"
              value={comments.bubbly}
              onChange={(e) => handleCommentChange('bubbly', e.target.value)}
              placeholder={language === 'ar' ? 'تعليقاتك حول الخدمة' : 'Your comments on the service'}
            ></textarea>

            {/* Cleanliness Section */}
            <h2>{language === 'ar' ? 'النظافة:' : 'Cleanliness:'}</h2>
            <p>{language === 'ar' ? 'كيف تقيم نظافة المطعم بشكل عام؟' : 'How would you rate the cleanliness of the restaurant overall?'}</p>
            <StarRating rating={ratings.cleanliness} onRatingChange={(value) => handleRatingChange('cleanliness', value)} />
            <textarea
              className="form-control"
              value={comments.cleanliness}
              onChange={(e) => handleCommentChange('cleanliness', e.target.value)}
              placeholder={language === 'ar' ? 'تعليقاتك حول النظافة' : 'Your comments on the cleanliness'}
            ></textarea>
          </div>

          {/* General Comment Section */}
          <div className="form-group">
            <label>{language === 'ar' ? 'هل لديك أي ملاحظات أو اقتراحات لتحسين تجربتك في لاستوريا؟' : 'Do you have any suggestions or comments to improve your experience at Lastoria?'}</label>
            <textarea
              className="form-control"
              value={generalComment}
              onChange={(e) => setGeneralComment(e.target.value)}
              placeholder={language === 'ar' ? 'أضف تعليقاتك هنا' : 'Add your comments here'}
            ></textarea>
          </div>

          {/* Email and Phone (Optional) */}
          <div className="form-group contact-info">
            <label>{language === 'ar' ? 'ليصلك كل جديد:' : 'To stay updated:'}</label>
            <div className="input-group">
              <FaEnvelope className="input-icon" />
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={language === 'ar' ? 'أدخل بريدك الإلكتروني' : 'Enter your email'}
              />
            </div>
            <div className="input-group">
              <FaPhone className="input-icon" />
              <input
                type="text"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder={language === 'ar' ? 'أدخل رقم هاتفك' : 'Enter your phone number'}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary mt-3">{language === 'ar' ? 'إرسال' : 'Submit'}</button>
        </form>
      </div>
    </div>
  );
};

export default RatingPage;
