import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { uploadBytes, getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { firestore, storage } from '../firebaseConfig'; 
import '../styles/ProductEditForm.css';

const ProductEditForm = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'menuItems'));
        const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleChange = async (e, productId) => {
    const { name, value, files } = e.target;

    if (name === 'image' && files.length > 0) {
      const newImageFile = files[0];
      const storageRef = ref(storage, `product_images/${productId}/${newImageFile.name}`);

      try {
        await uploadBytes(storageRef, newImageFile);
        const newImageUrl = await getDownloadURL(storageRef);

        await updateDoc(doc(firestore, 'menuItems', productId), { image: newImageUrl });

        setProducts(products.map(product =>
          product.id === productId ? { ...product, image: newImageUrl } : product
        ));
      } catch (error) {
        console.error("Error uploading image: ", error);
      }
    } else {
      await updateDoc(doc(firestore, 'menuItems', productId), { [name]: value });
      setProducts(products.map(product =>
        product.id === productId ? { ...product, [name]: value } : product
      ));
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteDoc(doc(firestore, 'menuItems', productId));
        setProducts(products.filter(product => product.id !== productId));
        alert('Product deleted successfully!');
      } catch (error) {
        console.error('Error deleting product: ', error);
        alert('Error deleting product. Please try again.');
      }
    }
  };

  const handleSave = async (productId) => {
    try {
      console.log('Product with ID', productId, 'saved successfully!');
      alert('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving product: ', error);
      alert('Error saving product. Please try again.');
    }
  };

  const filteredProducts = products.filter(product => {
    return (
      product.name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.name_ar.includes(searchTerm) ||
      product.description_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description_ar.includes(searchTerm) ||
      product.price.toString().includes(searchTerm)
    );
  });

  return (
    <div className="container mt-3">
      {/* <h2 className="mb-4">Admin Panel - Products</h2> */}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by name, description, or price"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {filteredProducts.map(product => (
        <div key={product.id} className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <img src={product.image} alt={product.name_en} style={{ width: '100%', height: 'auto' }} />
                <input type="file" className="form-control mt-2" name="image" onChange={(e) => handleChange(e, product.id)} />
              </div>
              <div className="col-md-8">
                <div className="mb-3">
                  <label htmlFor={`name_en_${product.id}`} className="form-label">Name (English)</label>
                  <input type="text" className="form-control" id={`name_en_${product.id}`} name="name_en" value={product.name_en} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label htmlFor={`name_ar_${product.id}`} className="form-label">Name (Arabic)</label>
                  <input type="text" className="form-control" id={`name_ar_${product.id}`} name="name_ar" value={product.name_ar} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label htmlFor={`description_en_${product.id}`} className="form-label">Description (English)</label>
                  <textarea className="form-control" id={`description_en_${product.id}`} name="description_en" value={product.description_en} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label htmlFor={`description_ar_${product.id}`} className="form-label">Description (Arabic)</label>
                  <textarea className="form-control" id={`description_ar_${product.id}`} name="description_ar" value={product.description_ar} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label htmlFor={`price_${product.id}`} className="form-label">Price</label>
                  <input type="number" className="form-control" id={`price_${product.id}`} name="price" value={product.price} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary me-2" onClick={() => handleSave(product.id)}>Save</button>
                  <button className="btn btn-danger" onClick={() => handleDelete(product.id)}>Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductEditForm;
