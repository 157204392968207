// In a new file, e.g., components/Redirect.js
import { useEffect } from 'react';

const Redirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // This component doesn't render anything
};

export default Redirect;
