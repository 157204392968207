import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import '../styles/StarDisplay.css';

const StarDisplay = ({ rating, onStarClick }) => {
  const totalStars = 5;
  let stars = [];

  // Ensure rating is numeric and valid
  const numericRating = parseFloat(rating) || 0;
  const roundedRating = Math.round(numericRating * 2) / 2; // Round to nearest half

  for (let i = 1; i <= totalStars; i++) {
    // Generate full, half, or empty stars based on the rating
    if (i <= roundedRating) {
      stars.push(
        <FaStar
          key={i}
          className="star"
          onClick={() => onStarClick && onStarClick(i)} // Only handle click if onStarClick exists
        />
      );
    } else if (i === Math.ceil(roundedRating) && !Number.isInteger(roundedRating)) {
      stars.push(
        <FaStarHalfAlt
          key={i}
          className="star"
          onClick={() => onStarClick && onStarClick(i)}
        />
      );
    } else {
      stars.push(
        <FaRegStar
          key={i}
          className="star"
          onClick={() => onStarClick && onStarClick(i)}
        />
      );
    }
  }

  return <div className="star-rating">{stars}</div>;
};

export default StarDisplay;