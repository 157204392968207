import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import StarDisplay from './StarDisplay';
import '../styles/RatingsResults.css';

const RatingsResults = () => {
  const [ratingsData, setRatingsData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch ratings from Firestore
  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'ratings'));
        const ratings = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRatingsData(ratings);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching ratings:', error);
        setLoading(false);
      }
    };

    fetchRatings();
  }, []);

  // Handle hiding a rating
  const handleHideRating = async (id) => {
    try {
      await updateDoc(doc(firestore, 'ratings', id), {
        hidden: true, // Set hidden status to true in Firestore
      });
      setRatingsData(ratingsData.map(rating => rating.id === id ? { ...rating, hidden: true } : rating));
    } catch (error) {
      console.error('Error hiding rating:', error);
    }
  };

  // Handle unhiding a rating
  const handleUnhideRating = async (id) => {
    try {
      await updateDoc(doc(firestore, 'ratings', id), {
        hidden: false, // Set hidden status to false in Firestore
      });
      setRatingsData(ratingsData.map(rating => rating.id === id ? { ...rating, hidden: false } : rating));
    } catch (error) {
      console.error('Error unhiding rating:', error);
    }
  };

  if (loading) {
    return <p>Loading ratings...</p>;
  }

  // Separate visible and hidden ratings
  const visibleRatings = ratingsData.filter(rating => !rating.hidden);
  const hiddenRatings = ratingsData.filter(rating => rating.hidden);

  return (
    <div className="ratings-results-container">
      <h2>Ratings Results</h2>
      
      {/* Visible Ratings */}
      <div className="average-rating-section">
        <h3>Visible Ratings</h3>
        {visibleRatings.length === 0 ? (
          <p>No visible ratings available.</p>
        ) : (
          visibleRatings.map((rating, index) => (
            <div key={index} className="rating-item">
              <div className="rating-header">
                <strong>{rating.name || 'Anonymous'}</strong>
                <StarDisplay rating={rating.rating} />
              </div>
              <p>{rating.comment || 'No comments provided'}</p>
              <button className="btn btn-warning" onClick={() => handleHideRating(rating.id)}>
                Hide
              </button>
            </div>
          ))
        )}
      </div>

      {/* Hidden Ratings */}
      <div className="hidden-ratings">
        <h3>Hidden Ratings</h3>
        {hiddenRatings.length === 0 ? (
          <p>No hidden ratings.</p>
        ) : (
          hiddenRatings.map((rating, index) => (
            <div key={index} className="rating-item">
              <div className="rating-header">
                <strong>{rating.name || 'Anonymous'}</strong>
                <StarDisplay rating={rating.rating} />
              </div>
              <p>{rating.comment || 'No comments provided'}</p>
              <button className="btn btn-success" onClick={() => handleUnhideRating(rating.id)}>
                Unhide
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default RatingsResults;
