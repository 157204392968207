import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import RatingComponent from './RatingComponent';
import { firestore } from '../firebaseConfig';
import { NavContext } from '../App';
import StarDisplay from './StarDisplay';
import '../styles/HomePage.css';

const HomePage = () => {
  const { toggleNav } = useContext(NavContext); // NavContext for sidebar navigation
  const [totalRating, setTotalRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [showRatingComponent, setShowRatingComponent] = useState(false); // State to control RatingComponent
  const [language, setLanguage] = useState('En');
  const [clientComments, setClientComments] = useState([]);
  const [visibleComments, setVisibleComments] = useState(5);

  useEffect(() => {
    const fetchRatingsAndComments = async () => {
      try {
        const ratingsCollection = collection(firestore, 'ratings');
        const querySnapshot = await getDocs(ratingsCollection);

        let total = 0;
        let count = 0;
        let commentsArray = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const rating = typeof data.rating === 'string' ? parseFloat(data.rating) : data.rating;

          if (!isNaN(rating) && !data.hidden) { // Only valid, visible ratings
            total += rating;
            count++;
          }

          if (data.comment && data.name && !data.hidden) {
            commentsArray.push({
              name: data.name,
              comment: data.comment,
              rating: rating
            });
          }
        });

        if (count > 0) {
          setTotalRating(total);
          setRatingCount(count);
          setAverageRating(total / count);
        }

        setClientComments(commentsArray);
      } catch (error) {
        console.error("Error fetching ratings and comments:", error);
      }
    };

    fetchRatingsAndComments();
  }, []);

  const handleSeeMoreClick = () => {
    setVisibleComments((prevVisibleComments) => prevVisibleComments + 5);
  };

  const handleAddRateClick = () => {
    setShowRatingComponent(true); // Open the rating component/modal
  };

  const closeRatingComponent = () => {
    setShowRatingComponent(false); // Close the rating component/modal
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'En' ? 'Ar' : 'En'));
  };

  return (
    <div className="homepage page-background">
      <button onClick={toggleLanguage} className="language-toggle">
        {language === 'En' ? 'عربي' : 'English'}
      </button>
      <div className="hero-section">
        <h1>{language === 'En' ? 'Welcome to Lastoria Restaurant' : 'مرحبا بكم في مطعم لاستوريا'}</h1>
        <p>{language === 'En' ? 'Lastoria is your comfort zone, with your friends, with your family, and for business meetings, for enjoyable moments.' : 'لاستوريا هو المنطقة الراحتك الخاصة بك، مع أصدقائك، مع عائلتك، ولاجتماعات العمل، و للحظات ممتعة'}</p>
      </div>
      <div className="container">
        <section className="rating-section">
          <h3 className='average-rating'>{language === 'En' ? 'Average Rating' : 'التقييم العام'}</h3>
          <StarDisplay rating={averageRating} />
          <p className='Rate-section'>{averageRating.toFixed(1)} ({ratingCount} <span className='Rate-section'>{language === 'En' ? 'ratings' : 'تقييمات'}</span>)</p>
          <button className='addrate' onClick={handleAddRateClick}><h4>{language === 'En' ? 'Add Rate' : 'أضف تقييم'}</h4></button>
          {showRatingComponent && (
            <div className="rating-modal">
              <RatingComponent language={language} closeModal={closeRatingComponent} />
            </div>
          )}
          <h4 className="client-comments-header">{language === 'En' ? 'What our Clients Say' : 'ما يقوله عملاؤنا'}</h4>
          <div className="client-comments">
            {clientComments.slice(0, visibleComments).map((item, index) => (
              <div key={index} className="client-comment">
                <div className="comment-header">
                  <strong>{item.name}</strong>
                  <StarDisplay rating={item.rating} size={20} />
                </div>
                <p>{item.comment}</p>
              </div>
            ))}
          </div>
          {visibleComments < clientComments.length && (
            <button onClick={handleSeeMoreClick} className="see-more-btn">
              {language === 'En' ? 'See More' : 'رؤية المزيد'}
            </button>
          )}
        </section>
      </div>
    </div>
  );
};

export default HomePage;
