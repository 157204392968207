// ModalComponent.js
import React from 'react';
import '../styles/ModalComponent.css'; // Assuming you'll style it separately

const ModalComponent = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
