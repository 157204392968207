import React, { useEffect, useState } from 'react';
import { collection, query, onSnapshot, getDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useAuth } from './auth/AuthContext'; // Assuming you have AuthContext to get the current user
import { FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalComponent from './ModalComponent'; // Import the reusable modal component
import '../styles/NotificationsPage.css';

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const { currentUser } = useAuth(); // Get the current logged-in user

  // Fetch notifications for the current user
  useEffect(() => {
    if (currentUser) {
      const userNotificationsRef = collection(firestore, `users/${currentUser.uid}/notifications`);
      const q = query(userNotificationsRef);

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const notificationsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log('Fetched Notifications:', notificationsData); // Debugging log
          setNotifications(notificationsData);
        } else {
          console.log('No notifications found');
        }
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  // Fetch rating details when a notification is clicked
  const handleNotificationClick = async (notification) => {
    try {
      const ratingDoc = await getDoc(doc(firestore, 'ratings', notification.ratingId));
      if (ratingDoc.exists()) {
        setSelectedNotification({
          ...notification,
          ratingDetails: ratingDoc.data(),
        });
        setShowModal(true); // Open modal on notification click
      }
    } catch (error) {
      console.error('Error fetching rating details:', error);
      toast.error('Failed to load rating details');
    }
  };

  // Mark notification as read
  const markAsRead = async (id) => {
    try {
      await updateDoc(doc(firestore, `users/${currentUser.uid}/notifications`, id), { isRead: true });
      setNotifications(
        notifications.map((notif) =>
          notif.id === id ? { ...notif, isRead: true } : notif
        )
      );
      toast.success('Notification marked as read');
      setShowModal(false);
    } catch (error) {
      toast.error('Error marking notification as read');
      console.error('Error marking as read:', error);
    }
  };

  // Hide the rating
  const hideRating = async (ratingId) => {
    try {
      await updateDoc(doc(firestore, 'ratings', ratingId), { hidden: true });
      toast.success('Rating hidden successfully!');
      setShowModal(false);
    } catch (error) {
      toast.error('Error hiding rating');
      console.error('Error hiding rating:', error);
    }
  };

  // Open delete confirmation modal
  const openDeleteConfirmation = (notificationId) => {
    setNotificationToDelete(notificationId);
    setShowDeleteModal(true);
  };

  // Delete a notification after confirmation
  const handleDeleteNotification = async () => {
    if (notificationToDelete) {
      try {
        await deleteDoc(doc(firestore, `users/${currentUser.uid}/notifications`, notificationToDelete));
        setNotifications(notifications.filter((notif) => notif.id !== notificationToDelete));
        toast.success('Notification deleted successfully.');
        setShowDeleteModal(false);
      } catch (error) {
        toast.error('Error deleting notification');
        console.error('Error deleting notification:', error);
      }
    }
  };

  if (loading) {
    return <p>Loading notifications...</p>;
  }

  return (
    <div className="notifications-page">
      <ToastContainer /> {/* Toast notification container */}
      <h2>Notifications</h2>
      {notifications.length === 0 ? (
        <p>No notifications available.</p>
      ) : (
        <div className="notification-list">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`notification-item ${notification.isRead ? 'read' : 'unread'}`}
              onClick={() => handleNotificationClick(notification)}
            >
              <p>{notification.message}</p>
              <small>{new Date(notification.createdAt?.seconds * 1000).toLocaleString()}</small>
              <FaTrash
                className="delete-icon"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the notification click event
                  openDeleteConfirmation(notification.id); // Open delete confirmation
                }}
              />
            </div>
          ))}
        </div>
      )}

      {/* Modal for notification details */}
      <ModalComponent isOpen={showModal} onClose={() => setShowModal(false)}>
        {selectedNotification && selectedNotification.ratingDetails ? (
          <>
            <h3>Rating Details</h3>
            <p><strong>Name:</strong> {selectedNotification.ratingDetails.name}</p>
            <p><strong>Rating:</strong> {selectedNotification.ratingDetails.rating}</p>
            <p><strong>Comment:</strong> {selectedNotification.ratingDetails.comment}</p>
            <div className="buttons">
              <button
                onClick={() => hideRating(selectedNotification.ratingId)}
                className="btn btn-danger"
              >
                Hide
              </button>
              <button
                onClick={() => markAsRead(selectedNotification.id)}
                className="btn btn-primary"
              >
                Mark as Read
              </button>
            </div>
          </>
        ) : (
          <p>No rating details available.</p>
        )}
      </ModalComponent>

      {/* Modal for delete confirmation */}
      <ModalComponent isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <h3>Confirm Deletion</h3>
        <p>Are you sure you want to delete this notification?</p>
        <div className="buttons">
          <button onClick={handleDeleteNotification} className="btn btn-danger">
            Yes, Delete
          </button>
          <button onClick={() => setShowDeleteModal(false)} className="btn btn-secondary">
            Cancel
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default NotificationsPage;
