import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore } from '../firebaseConfig';

const ProductUploadForm = () => {
  const [formData, setFormData] = useState({
    name_en: '',
    name_ar: '',
    description_en: '',
    description_ar: '',
    price: '',
    category: '',
    subcategory: '',
    image: null
  });

  const categories = ["Food", "Drink", "Sweet", "Bubbly"];
  const subcategories = [
    "Soup", "Salads", "Appetizers", "Sandwiches", "Italian Meals", "Pizza", "Main Meals", "Barbecue Dishes", "Breakfast",
    "Hot Drinks", "Cocktails", "Natural Juice", "Ice Drinks", "Milkshake", "Mojito", "Soft Drinks", "Candies", "Bubbly"
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image' && files.length) {
      setFormData({ ...formData, image: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageURL = '';
      if (formData.image) {
        const storage = getStorage();
        const storageRef = ref(storage, `product_images/${formData.image.name}`);
        const snapshot = await uploadBytes(storageRef, formData.image);
        imageURL = await getDownloadURL(snapshot.ref);
      } else {
        // Set the URL to the default image
        const storage = getStorage();
        const defaultImageRef = ref(storage, 'product_images/default.png');
        imageURL = await getDownloadURL(defaultImageRef);
      }

      // Proceed with adding the document to Firestore
      const productsCollectionRef = collection(firestore, 'menuItems');
      await addDoc(productsCollectionRef, {
        ...formData,
        image: imageURL, // This will now be the uploaded image or the default
        price: formData.price ? parseFloat(formData.price) : 0 // Handle no price entered
      });
      alert('Product uploaded successfully!');
      
      // Reset form after submission
      setFormData({
        name_en: '',
        name_ar: '',
        description_en: '',
        description_ar: '',
        price: '',
        category: '',
        subcategory: '',
        image: null
      });
    } catch (error) {
      console.error("Error uploading product: ", error);
      alert('Error uploading product. Please try again.');
    }
  };

  return (
    <div className="container mt-3">
      <h2 className="mb-4">Upload New Product</h2>
      <form onSubmit={handleSubmit}>
        {/* Form fields */}
        <div className="mb-3">
          <input type="text" className="form-control" name="name_en" placeholder="Name in English" value={formData.name_en} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <input type="text" className="form-control" name="name_ar" placeholder="اسم بالعربي" value={formData.name_ar} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <textarea className="form-control" name="description_en" placeholder="Description in English" value={formData.description_en} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <textarea className="form-control" name="description_ar" placeholder="وصف بالعربي" value={formData.description_ar} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <input type="number" className="form-control" name="price" placeholder="Price" value={formData.price} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <select className="form-select" name="category" value={formData.category} onChange={handleChange} required>
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <select className="form-select" name="subcategory" value={formData.subcategory} onChange={handleChange} required>
            <option value="">Select Subcategory</option>
            {subcategories.map(subcategory => (
              <option key={subcategory} value={subcategory}>{subcategory}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <input type="file" className="form-control" name="image" onChange={handleChange} />
        </div>
        <button type="submit" className="btn btn-primary">Upload Product</button>
      </form>
    </div>
  );
};

export default ProductUploadForm;
