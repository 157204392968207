import React, { useContext, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NavContext } from '../App';
import '../styles/NavBar.css';
import { FaTimes } from 'react-icons/fa';

const NavBar = () => {
  const { navExpanded, toggleNav, closeNav } = useContext(NavContext);

  const handleClickOutside = (event) => {
    if (navExpanded && !event.target.closest('.navbar-custom')) {
      closeNav();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [navExpanded]);

  return (
    <Navbar expanded={navExpanded} expand="lg" variant="dark" className="navbar-custom">
      <Navbar.Brand href="/">Lastoria Restaurant</Navbar.Brand>
      <Navbar.Toggle onClick={toggleNav} aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <div className="nav-center">
          <Nav className="mr-auto" onClick={closeNav}>
            <Nav.Link as={Link} to="/category/Food">Food</Nav.Link>
            <Nav.Link as={Link} to="/category/Drink">Drink</Nav.Link>
            <Nav.Link as={Link} to="/category/Sweet">Sweet</Nav.Link>
            <Nav.Link as={Link} to="/category/Bubbly">Bubbly</Nav.Link>
          </Nav>
        </div>
        <div className="rating-section">
          <p className="rating-message">We value your feedback! Please leave a rating.</p>
          <Nav.Link as={Link} to="/rating" className="rating-link" onClick={closeNav}>Rating</Nav.Link>
        </div>
        {/* Close button */}
        <div className="nav-close-icon" onClick={closeNav}>
          <FaTimes size={24} />
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
